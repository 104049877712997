import React from 'react';

import './Gallery.scss';

import bwell from '../../images/portfolio/Bwellgym-print.jpg';
import caister from '../../images/portfolio/caisterroofing-web.jpg';
import clive from '../../images/portfolio/Clivetownshend.jpg';
import gywall from '../../images/portfolio/greatwallofyarmouth-web.jpg';
import gyaf from '../../images/portfolio/gyaf-web.jpg';
import nhs from '../../images/portfolio/nhs-poster.jpg';
import sfl from '../../images/portfolio/ScrobyFayre-banner.jpg';
import sscs from '../../images/portfolio/sscs-web.jpg';
import stimwell from '../../images/portfolio/stimwell-web.jpg';

const portfolio = [
  {
    name: 'sscs',
    img: sscs,
    text:
      'SSCS website, full CMS design with client ability to update all content, mobile responsive adapting to the visitors screen depending on the size of the browser window.',
  },
  {
    name: 'bwell',
    img: bwell,
    text:
      'BWell Gym, selection of printed materials for a local gym including flyers and pull up banners',
  },
  {
    name: 'clive',
    img: clive,
    text:
      'Full package for motor mechanic, everything from business cards to flyers and a basic website',
  },
  {
    name: 'gyaf',
    img: gyaf,
    text:
      'Great Yarmouth Arts Festival website, complete with full calendar of upcoming events as well as details of venues and local information. For the past 2 years I have been part of the team updating the website content',
  },
  {
    name: 'nhs',
    img: nhs,
    text:
      'Local poster campaign for the NHS East Norfolk Medical Center designed to try and highlight the amount of missed appointments and the cost to the NHS as a result',
  },
  {
    name: 'gywall',
    img: gywall,
    text:
      'Website and Email campaign to help promote a local authors book surrounding the history of the Great Yarmouth wall and how it helped shaped the town.',
  },
  {
    name: 'stimwell',
    img: stimwell,
    text:
      'StimWell Services website, full CMS design with client ability to update latest news, mobile responsive adapting to the visitors screen depending on the size of the browser window.',
  },
  {
    name: 'caister',
    img: caister,
    text:
      'Caister Roofing website, designed for a local roofing specialist. Mobile responsive including gallery of existing work.',
  },
  {
    name: 'sfl',
    img: sfl,
    text:
      'Scroby Fayre Ltd poster for display in their local offices, showing their current fleet of vehicles and plant machinery',
  },
];

const Gallery = () => (
  <div id="gallery">
    {portfolio.map(portfolioItem => (
      <div key={portfolioItem.name} className="gallery-item">
        <img src={portfolioItem.img} alt={portfolioItem.name} />
        <p>{portfolioItem.text}</p>
      </div>
    ))}
  </div>
);

export default Gallery;
