import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Textblock from '../components/textblock/Textblock';
import Fullblock from '../components/fullblock/Fullblock';
import Gallery from '../components/gallery/Gallery';
import ContactForm from '../components/contactform/ContactForm';

export default () => (
  <Layout>
    <Helmet
      title="NathanGriffen // eternityDigital :: Digital Designer // Front End Web Developer"
      meta={[
        {
          name: 'description',
          content:
            'Here are some examples of projects I have been a part of with a small description of techniques and technologies used',
        },
        {
          name: 'keywords',
          content: 'digital media, design, websites, dedicated service, print, gallery, portfolio',
        },
      ]}
    />
    <main>
      <Fullblock>
        <h2>Projects</h2>
      </Fullblock>
      <Textblock textblockTitle="Examples of Work">
        <p>Here are some examples of projects I have been a part of with a small description.</p>
        <p>
          If you&apos;d like to know more please send me an email
          {' '}
          <a href="mailto:nathan@eternitydigital.com">nathan@eternityDigital.com</a>
.
        </p>
      </Textblock>
      <Gallery />
    </main>
    <ContactForm formTitle="Let's talk!" />
  </Layout>
);
